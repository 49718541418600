


















































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import LanguageSelect from "@/components/layout/components/LanguageSelect.vue";

@Component({ components: { LanguageSelect } })
export default class AuthPage extends Vue {
  @Getter("appLoading") appLoading!: boolean;
}
